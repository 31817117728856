
import menubar from '../../components/ui-components/oil-menubar/menubar-cmp.vue'
import axios from 'axios'
import { defineComponent, reactive, onMounted } from "vue"
import { Chart } from 'highcharts-vue'

export default defineComponent({
    setup() {
        const menubar = reactive([
            {
                name: "Общее",
                id: 1
            },
            {
                name: "Скважины",
                id: 2
            },
            {
                name: "Обустройство",
                id: 3
            },
            {
                name: "Коммуникации",
                id: 4
            },
            {
                name: "Прочее",
                id: 5
            },
        ])

        const active_menubar = reactive({
            value: 1
        })

        const active_subrow = reactive({
            value: 0
        })

        const active_list = reactive({
            value: ''
        })

        const params_hightchart = reactive({
            params: {
                title: {
                    text: 'Logarithmic axis demo'
                },

                xAxis: {
                    tickInterval: 1,
                    type: 'logarithmic',
                    accessibility: {
                        rangeDescription: 'Range: 1 to 10'
                    }
                },

                yAxis: {
                    type: 'logarithmic',
                    minorTickInterval: 0.1,
                    accessibility: {
                        rangeDescription: 'Range: 0.1 to 1000'
                    }
                },

                tooltip: {
                    headerFormat: '<b>{series.name}</b><br />',
                    pointFormat: 'x = {point.x}, y = {point.y}'
                },

                series: [{
                    data: [1, 2, 4, 8, 16, 32, 64, 128, 256, 512],
                    pointStart: 1
                }]
            }
        })

        const borehole_economy_table = reactive({
            borehole_table: [ //delete
                {
                    id: 0,
                    boreholeName: {
                        text: "Скважина"
                    },
                    boreholeStatus: {
                        text: "Статус"
                    },
                    capex: {
                        text: "Capex"
                    },
                    accumulatedOpex: {
                        text: "OPEX (накопленный)"
                    },
                    currentOpex: {
                        text: "OPEX (текущий)"
                    },
                    tu: {
                        text: 'TU'
                    },
                    wfu: {
                        text: 'WFU'
                    },
                    eu: {
                        text: 'EU'
                    }
                },
                {
                    id: 1,
                    boreholeName: {
                        text: "P1 (Разведывательная)",
                        coords: {
                            cell:{
                                x:1,
                                y:1,
                            },
                            region:{
                                x:1,
                                y:1,
                            },
                        }
                    },
                    boreholeStatus: {
                        text: "Исследование",
                        color: "#DCFFF0"
                    },
                    capex: {
                        text: "-25000"
                    },
                    accumulatedOpex: {
                        text: "-25000"
                    },
                    currentOpex: {
                        text: "-25000"
                    },
                    tu: {
                        text: '50'
                    },
                    wfu: {
                        text: '-31'
                    },
                    eu: {
                        text: '-31'
                    }
                }
            ] as any,
            arrangement_table: [ //delete
                {
                    id: 0,
                    arrangmentName: {
                        text: "Объект обустройства"
                    },
                    arrangmentStatus: {
                        text: "Статус"
                    },
                    capex: {
                        text: "Capex"
                    },
                    accumulatedOpex: {
                        text: "OPEX (накопленный)"
                    },
                    currentOpex: {
                        text: "OPEX (текущий)"
                    },
                    tu: {
                        text: 'TU'
                    },
                    wfu: {
                        text: 'WFU'
                    },
                    eu: {
                        text: 'EU'
                    }
                },
                {
                    id: 1,
                    arrangmentName: {
                        text: "Мини УПСВ",
                        coords: {
                            cell:{
                                x:1,
                                y:1,
                            },
                            region:{
                                x:1,
                                y:1,
                            },
                        }
                    },
                    arrangmentStatus: {
                        text: "Исследование",
                        color: "#DCFFF0"
                    },
                    capex: {
                        text: "-25000"
                    },
                    accumulatedOpex: {
                        text: "-25000"
                    },
                    currentOpex: {
                        text: "-25000"
                    },
                    tu: {
                        text: '50'
                    },
                    wfu: {
                        text: '-31'
                    },
                    eu: {
                        text: '-31'
                    }
                }
            ] as any,
            communication_table: [ //delete
                {
                    id: 0,
                    communicationName: {
                        text: "Коммуникации"
                    },
                    communicationStatus: {
                        text: "Статус"
                    },
                    capex: {
                        text: "Capex"
                    },
                    accumulatedOpex: {
                        text: "OPEX (накопленный)"
                    },
                    currentOpex: {
                        text: "OPEX (текущий)"
                    },
                    tu: {
                        text: 'TU'
                    },
                    wfu: {
                        text: 'WFU'
                    },
                    eu: {
                        text: 'EU'
                    }
                },
                {
                    id: 1,
                    communicationName: {
                        text: "Нефтепровод 1",
                        coords: {
                            cell:{
                                x:1,
                                y:1,
                            },
                            region:{
                                x:1,
                                y:1,
                            },
                        }
                    },
                    communicationStatus: {
                        text: "Исследование",
                        color: "#DCFFF0"
                    },
                    capex: {
                        text: "-25000"
                    },
                    accumulatedOpex: {
                        text: "-25000"
                    },
                    currentOpex: {
                        text: "-25000"
                    },
                    tu: {
                        text: '50'
                    },
                    wfu: {
                        text: '-31'
                    },
                    eu: {
                        text: '-31'
                    }
                }
            ] as any,
            other_table: [
                {
                    researchName: {
                        text: "Вид работы"
                    },
                    researchCapex: {
                        text: "CAPEX",
                    },
                    researchAccumulatedOpex: {
                        text: "OPEX",
                        sub: "накопленный"
                    },
                    researchСurrentOpex: {
                        text: "OPEX",
                        sub: "текущий"
                    },
                    researchTu: {
                        text: "TU"
                    },
                    researchWfu: {
                        text: "WFU"
                    },
                    researchEu: {
                        text: "EU"
                    },
                },
            ] as any
        })

        const table_text = reactive({
            mark: {
                pi: 'PI (Profitability index – Коэффициент рентабельности), %',
                ndcp: 'NDCP (Net discounted cash profit – Чистая дисконтированная денежная прибыль), OilCoins'
            },
            taxes: {
                vat: 'Налог на добавленную стоимость (20%)',
                cit: 'Налог на прибыль (15%)',
                wpt: 'Налог на дополнительный доход (10%)',
                pt: 'Налог на имущество (2,2%)',
                lt: 'Налог на землю (1,5%)',
                ip: 'Страховые выплаты (45%)'
            }
        })

        const endpoints = [
            {   
                url: 'Views/Browser/Economics/Borehole'
            },
            {   
                url: 'Views/Browser/Economics/ObjectOfArrangement'
            },
            {   
                url: 'Views/Browser/Economics/ServiceLines'
            },
            {   
                url: 'Views/Browser/Economics/Other/WellLog'
            },
            {   
                url: 'Views/Browser/Economics/Other/WellLogInterpretation'
            },
            {   
                url: 'Views/Browser/Economics/Other/FieldGeophysicalSurvey'
            },
            {   
                url: 'Views/Browser/Economics/Other/FlowTest'
            },
            {   
                url: 'Views/Browser/Economics/Other/ProductionLogging'
            },
            {
                url: 'Views/Browser/Economics/General'
            },
            // {
            //     url: '/Views/Browser/Economics/Other/GTM'
            // },
            {
                url: 'Views/Browser/Economics/Other/Seismic'
            },
            {
                url: 'Views/Browser/Economics/Other/Maps'
            },
            {
                url: 'Views/Browser/Economics/Other/3dModel'
            }
        ]

        const table_economy = reactive({
            economy_borehole: [] as any, 
            economy_arrangment: [] as any,
            economy_communication: [] as any,
            gis: {} as any,
            rigis: {} as any,
            pgi: {} as any,
            gdi: {} as any,
            gdk: {} as any,
            economy_general_chart: {} as any,
            economy_general_dcf: {} as any,
            economy_gtm: {} as any,
            seismic: {} as any,
            maps: {} as any,
            model: {} as any,
            economy_mark: [] as any,
            economy_taxes: [] as any,
        })

        const openSubRow = (type: string) => {
            active_list.value = active_list.value === type ? '': type
        }

        const selectTabs = (tab: number) => {
            active_menubar.value = tab
        }

        const total_2024 = (val) => {            
            const result = val.reduce((accumulator, currentValue) => {
                return Number(accumulator) + Number(currentValue[2024])
            }, 0)
            
            if (isNaN(result)) {
                return "-"
            }
            
            return result.toString().replace(/\B(?=(\d{3})+(?!\d))/g, " ")
        }

        const total_2025 = (val) => {            
            const result = val.reduce((accumulator, currentValue) => {
                return Number(accumulator) + Number(currentValue[2025])
            }, 0)
            
            if (isNaN(result)) {
                return "-"
            }
            
            return result.toString().replace(/\B(?=(\d{3})+(?!\d))/g, " ")
        }

        const total_2026 = (val) => {            
            const result = val.reduce((accumulator, currentValue) => {
                return Number(accumulator) + Number(currentValue[2026])
            }, 0)
            
            if (isNaN(result)) {
                return "-"
            }
            
            return result.toString().replace(/\B(?=(\d{3})+(?!\d))/g, " ")
        }

        const total_2027 = (val) => {            
            const result = val.reduce((accumulator, currentValue) => {
                return Number(accumulator) + Number(currentValue[2027])
            }, 0)
            
            if (isNaN(result)) {
                return "-"
            }
            
            return result.toString().replace(/\B(?=(\d{3})+(?!\d))/g, " ")
        }

        const total_2028 = (val) => {            
            const result = val.reduce((accumulator, currentValue) => {
                return Number(accumulator) + Number(currentValue[2028])
            }, 0)
            
            if (isNaN(result)) {
                return "-"
            }
            
            return result.toString().replace(/\B(?=(\d{3})+(?!\d))/g, " ")
        }

        const total_2029 = (val) => {            
            const result = val.reduce((accumulator, currentValue) => {
                return Number(accumulator) + Number(currentValue[2029])
            }, 0)
            
            if (isNaN(result)) {
                return "-"
            }
            
            return result.toString().replace(/\B(?=(\d{3})+(?!\d))/g, " ")
        }

        const total_2030 = (val) => {            
            const result = val.reduce((accumulator, currentValue) => {
                return Number(accumulator) + Number(currentValue[2030])
            }, 0)
            
            if (isNaN(result)) {
                return "-"
            }
            
            return result.toString().replace(/\B(?=(\d{3})+(?!\d))/g, " ")
        }

        const total_2031 = (val) => {            
            const result = val.reduce((accumulator, currentValue) => {
                return Number(accumulator) + Number(currentValue[2031])
            }, 0)
            
            if (isNaN(result)) {
                return "-"
            }
            
            return result.toString().replace(/\B(?=(\d{3})+(?!\d))/g, " ")
        }

        const total_2032 = (val) => {            
            const result = val.reduce((accumulator, currentValue) => {
                return Number(accumulator) + Number(currentValue[2032])
            }, 0)
            
            if (isNaN(result)) {
                return "-"
            }
            
            return result.toString().replace(/\B(?=(\d{3})+(?!\d))/g, " ")
        }

        const total_2033 = (val) => {            
            const result = val.reduce((accumulator, currentValue) => {
                return Number(accumulator) + Number(currentValue[2033])
            }, 0)
            
            if (isNaN(result)) {
                return "-"
            }
            
            return result.toString().replace(/\B(?=(\d{3})+(?!\d))/g, " ")
        }

        const get_tax_text = (val) => {            
            return table_text.taxes[val]
        }

        const get_mark_text = (val) => {
            return table_text.mark[val]
        }
        
        onMounted(() => {
            
            axios.all(endpoints.map((endpoint) => axios.get(endpoint.url)))
                .then(
                    axios.spread((economy_borehole_response, economy_arrangment_response, economy_communication_response, economy_welllog_response, economy_intepretation_response, economy_geophysical_survey_response, economy_flow_test_response, economy_production_response, economy_general_response, economy_seismic_response, economy_maps_response, economy_model_response) => {
                    
                    table_economy.economy_general_dcf = economy_general_response.data.chart.dcf
                    table_economy.economy_general_chart = economy_general_response.data.chart.chart
                    table_economy.economy_borehole = economy_borehole_response.data
                    table_economy.economy_arrangment = economy_arrangment_response.data
                    table_economy.economy_communication = economy_communication_response.data
                    // table_economy.economy_gtm = economy_gtm_response.data
                    table_economy.seismic = economy_seismic_response.data
                    table_economy.maps = economy_maps_response.data
                    table_economy.model = economy_model_response.data
                    table_economy.gis = economy_welllog_response.data
                    table_economy.rigis = economy_intepretation_response.data
                    table_economy.pgi = economy_geophysical_survey_response.data
                    table_economy.gdi = economy_flow_test_response.data
                    table_economy.gdk = economy_production_response.data
                    table_economy.economy_mark = economy_general_response.data.marks
                    table_economy.economy_taxes = economy_general_response.data.taxes
                }))
                .finally(() => {
                    table_economy.economy_arrangment.unshift({
                        id: 0,
                        name: "Объект обустройства",
                        status: "Статус",
                        capex: "CAPEX",
                        opexTotal: "OPEX",
                        subOpexTotal: "накопленный",
                        opexCurrent: "OPEX",
                        subOpexCurrent: "текущий",
                        tu: 'TU',
                        wfu: 'WFU',
                        eu: 'EU'
                    })
                    table_economy.economy_communication.unshift({
                        id: 0,
                        name: "Коммуникации",
                        status: "Статус",
                        capex: "CAPEX",
                        opexTotal: "OPEX",
                        subOpexTotal: "накопленный",
                        opexCurrent: "OPEX",
                        subOpexCurrent: "текущий",
                        tu: 'TU',
                        wfu: 'WFU',
                        eu: 'EU'
                    })
                    table_economy.economy_borehole.unshift({
                        id: 0,
                        name: "Скважина",
                        capex: "CAPEX",
                        status: 'Статус',
                        opexTotal: "OPEX",
                        subOpexTotal: "накопленный",
                        opexCurrent: "OPEX",
                        subOpexCurrent: "текущий",
                        tu: 'TU',
                        wfu: 'WFU',
                        eu: 'EU'
                    })
                    table_economy.economy_mark.unshift({
                        id: 0,
                        name: "Показатель",
                        "2024": "2024",
                        "2025": "2025",
                        "2026": "2026",
                        "2027": "2027",
                        "2028": "2028",
                        "2029": "2029",
                        "2030": "2030",
                        "2031": "2031",
                        "2032": "2032",
                        "2033": "2033"
                    })
                    table_economy.economy_taxes.unshift({
                        id: 0,
                        name: "Налог",
                        "2024": "2024",
                        "2025": "2025",
                        "2026": "2026",
                        "2027": "2027",
                        "2028": "2028",
                        "2029": "2029",
                        "2030": "2030",
                        "2031": "2031",
                        "2032": "2032",
                        "2033": "2033"
                    })
                })})

        return {
            menubar,
            active_menubar,
            params_hightchart,
            borehole_economy_table,
            active_subrow,
            active_list,
            table_economy,
            table_text,
            selectTabs,
            openSubRow,
            total_2024,
            total_2025,
            total_2026,
            total_2027,
            total_2028,
            total_2029,
            total_2030,total_2031,
            total_2032,total_2033,
            get_tax_text,
            get_mark_text
        }
    },
    components: {
        'menubar-cmp': menubar,
        highcharts: Chart
    }
})

