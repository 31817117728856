 
import { reactive, defineComponent, watch } from 'vue'
import buttonCmp from '@/components/ui-components/oil-button/button-cmp.vue'
import radioCmp from '@/components/ui-components/oil-radio/radio-cmp.vue'
import checkboxCmp from '@/components/ui-components/oil-checkbox/checkbox-cmp.vue'
import axios from 'axios'
// import { useStore } from 'vuex'

    export default defineComponent({
        setup() {
            // const store = useStore()
            const loader = reactive({
                active: true as boolean
            })

            const disabled = reactive({
                value: false as boolean
            })

            const visibile_tooltip = reactive({
                value: false
            })

            const active_option = reactive({
                option_state: ''
            })

            const chronology = reactive({
                line: [] as Array<{
                    step: number,
                    link: string, 
                    loaded_model?: any,
                    option?: string
                }>
            })

            const layer_model = reactive({
                value: [
                    {
                        x: 1,
                        y: 2
                    }
                ]
            })

            const active_time = reactive({
                value: 0
            })

            const coords_select = reactive({
                x: [] as any,
                y: [] as any,
                chooses_x: [] as any,
                chooses_y: [] as any
            })

            const clearCoords = () => {
                if(coords_select.chooses_x.length || coords_select.chooses_y.length) {
                    coords_select.chooses_x = []
                    coords_select.chooses_y = []
                } else {
                    coords_select.chooses_x = coords_select.x
                    coords_select.chooses_y = coords_select.y
                }
            }

            const template_chronology = reactive({
                value: [] as any
            }) 
            
            const preloader = reactive({
                value: false as boolean
            })

            const model_property = reactive({
                value: [] as any
            })

            const model = reactive({
                value: '' as any
            })

            const selectXCoords = (x) => {
                if(!coords_select.x.find(item => item === x)) {
                    coords_select.x.push(x)
                } else {
                    const index = coords_select.x.indexOf(x);
                    if (index !== -1) {
                        coords_select.x.splice(index, 1);
                    }
                }
            }
            const selectYCoords = (y) => {
                if(!coords_select.y.find(item => item === y)) {
                    coords_select.y.push(y)
                } else {
                    const index = coords_select.y.indexOf(y);
                    if (index !== -1) {
                        coords_select.y.splice(index, 1);
                    }
                }
            }

            const view_state = reactive({
                value: 0
            })

            const template_active_date = reactive({
                value: null as number | null
            })

            const purchase_step = reactive({
                value: 0
            })

            const selectView = (state: number) => {
                view_state.value = state
                if(state === 1) {
                    axios
                        .get('Views/Browser/Model3D/ModelProperty')
                        .then((resp_property_model) => {
                            model_property.value = resp_property_model.data
                            coords_select.x = []
                            coords_select.y = []
                            coords_select.chooses_x = [] 
                            coords_select.chooses_y = []
                        })
                } else if(state === 2) {
                    axios
                        .get('Api/V1/LithologicalData/ModelProperty/SeismicLines')
                        .then((resp_property_model) => {
                            model_property.value = resp_property_model.data
                            coords_select.x = resp_property_model.data.xLines
                            coords_select.y = resp_property_model.data.yLines
                            coords_select.chooses_x = resp_property_model.data.xLines
                            coords_select.chooses_y = resp_property_model.data.yLines
                        })
                }
            }

            const setActiveTime = (time: number) => {
                active_time.value = time
            }

            const loadModel = () => {
                if(view_state.value === 2) {
                    axios
                        .post('api/v1/LithologicalData/ModelProperty/Seismic', {
                            purchaseStep: purchase_step.value,
                            xLines: coords_select.x,
                            yLines: coords_select.y
                        })
                        .then((resp_model) => {
                            model.value = resp_model.data
                        })
                        .finally(() => {
                            // preloader.value = false
                        })     
                } else {
                    axios
                        .post('api/v1/LithologicalData/ModelProperty', {
                            modelPropertyId: active_option.option_state,
                            purchaseStep: purchase_step.value,
                            xLines: coords_select.x,
                            yLines: coords_select.y
                        })
                        .then((resp_model) => {
                            model.value = resp_model.data
                        })
                        .finally(() => {
                            // preloader.value = false
                        })   
                }
            }

            const selectedDate = (idx: number, purchaseStep: number) => {
                template_active_date.value = idx
                purchase_step.value = purchaseStep
       
            }

            watch(() => preloader.value, () => {
                console.log(preloader.value);
            })

            // onMounted(() => {
            //     // loadData()
                
            //     axios
            //         .get('Views/Browser/Model3D/ModelProperty')
            //         .then((resp_property_model) => {
            //             model_property.value = resp_property_model.data
            //         })
            // })
            


            const active_date = reactive({
                value: 0 as number|string,
                play: false as boolean,
                speed: 1 as number,
                options_view: 1 as number
            })

            const setOptionsView = (state_option: string, date: number) => {
                active_option.option_state = state_option

                template_chronology.value = model_property.value.find(property => property.propertyId === state_option)!.steps

                template_active_date.value = template_chronology.value.length - 1

                selectedDate(template_active_date.value ,date)
            }


            return {
                loader,
                chronology,
                active_date,
                disabled,
                visibile_tooltip,
                active_option,
                setOptionsView,
                active_time,
                setActiveTime,
                template_chronology,
                selectedDate,
                template_active_date,
                selectYCoords,
                selectXCoords,
                model_property,
                model,
                preloader,
                view_state,
                selectView,
                coords_select,
                layer_model,
                loadModel,
                clearCoords
            }
        },
        components: {
            'radio-cmp': radioCmp,
            // 'input-cmp': inputCmp,
            // 'view-cmp': viewCmp 
            'btn-cmp': buttonCmp,
            'checkbox-cmp': checkboxCmp
        },
        methods: {
            setSegment(val) {
                this.active_date.value = val
            },
            // setOptionsView(val) {
            //     this.active_date.options_view = val
            //     this.active_model.value = this.model_link[val - 1].link
            // },
            setSpeed(val) {                
                this.active_date.speed = Number(val.value)
            }
        }
    })
